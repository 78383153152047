import { BrowserRouter as Router, Routes , Route} from 'react-router-dom'
import { Employee } from './Employee'
import "./App.css"

function App() {
  return (
    
    <Router >
      <Routes>
        <Route exact path="/"
          element={
            <div>
              <header className="header">
                <h1>No Verification Number provided</h1>
              </header>
            </div>
          }
        />

        <Route path="/:id"
          element={
            <div>
              <header className="header">
                <h1>Employee Verification</h1>
              </header>
              <Employee />
            </div>
          }
        />
      </Routes>
    </Router>
  )
}

export default App
