import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import employeeImage from "./waseem.jpeg"



export const Employee = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    const data = {
      name: "Waseem Hashim",
      designation: "Manager",
      active : true
    }
    setEmployeeData(data)
  }, []);


  if (!employeeData) {
    return <div>Loading...</div>;
  }

  if (id === "2onu8olabgeb0olbuqju")
  {
    return (
      <div className="employee-info-container">
            <div className="employee-avatar">
              <img
                src={employeeImage} // Use the imported image
                alt={employeeData.name}
                className="round-image"
              />
            </div>
            <div className="employee-details">
              <h2>{employeeData.name}</h2>
              <p className="designation">{employeeData.designation}</p>
              <p className={`status ${employeeData.active ? 'active' : 'inactive'}`}>
                {employeeData.active ? 'Active' : 'Not Active'}
              </p>
            </div>
      </div>
    )
  }
  else{
    return (
      <div className="employee-info-container">
          <h2 className='status inactive'>Employee Not Found</h2>
      </div>
    )
  }
}

